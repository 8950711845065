import { Option, Select, Typography } from "@mui/joy";
import { PageConfig } from "../../../pages/type";
import { Fragment, useEffect, useState } from "react";
import axios from "../../../config/axios.config";
import { AppModal } from "@app/components/AppModal";
import AppDatePicker from "@app/components/AppDatePicker";

type props = {
    onSuccess: () => void,
    pageConfig: PageConfig,
    open: boolean,
    onClose: () => void,
    idToUpdate: number[],
} 

export const AssignListeNoire = ({pageConfig, onSuccess, open, onClose, idToUpdate }: props) => {
    // Liste Noire Affecté à
    const [users, setUsers] = useState<any[]>([])
    const [selectedUsersId, setSelectedUsersId] = useState<number>()
    const [selectedDate, setSelectedDate] = useState<Date>()

    useEffect(() => {
        axios.get('/user-login/collaborateur').then(resp => setUsers(resp.data.result))
    }, [])

    const submit = () => {
        idToUpdate.forEach(id => {
            axios.patch(`/parrainages/${id}/liste-noire`, {
                ListeNoireAffecteeA: [selectedUsersId],
                dateListeNoire: selectedDate
            }).then(resp => {
                onSuccess();
                onClose();
            })
        })
    }

    
    const customSchemas = pageConfig.schemas.filter(schema => schema.form?.custom)
    return (
        <AppModal 
            handleSubmit={submit} 
            disabelSubmit={!selectedUsersId}
            icon='PencilSquareIcon' 
            title={`Affecter à`} 
            submitText="Affecter" 
            submitIcon="PencilSquareIcon"
            open={open} 
            onClose={onClose} 
            >
            {customSchemas.map(schema => 
                <Fragment key={schema.ref}>
                    {/* {schema.form?.custom === 'listeNoireAffecteeA' && */}
                        <div className="flex flex-col gap-2"> 
                            <Typography fontSize='sm' >Date de liste noire</Typography>
                            <AppDatePicker selected={selectedDate} setSelected={(value: any) => setSelectedDate(value)} />

                            <Typography fontSize='sm' >Affecté à</Typography>
                            <Select value={selectedUsersId} onChange={(_, value) => setSelectedUsersId(value as number)}>
                                {users.map(user => <Option value={user.id}>{user.userName}</Option>)}
                            </Select>
                        </div>
                    {/* } */}
                </Fragment>
            )}
        </AppModal>
    )
}
export default AssignListeNoire;


