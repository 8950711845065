import { IconName } from '../components/AppIcon';

export type optionItem =
    | 'create'
    | 'update'
    | 'add'
    | 'updateMany'
    | 'filter'
    | 'delete'
    | 'order'
    | 'search'
    | 'deleteMany'
    | 'createNewVersion'
    | 'newVersion'
    | 'updateCustom'
    | 'updateManyCustom';

export type DataExport = 'xml' | 'csv' | 'pdf' | 'json' | 'zip' | 'xlsx';
export type DataType =
    | 'ref'
    | 'money'
    | 'subSchema'
    | 'note'
    | 'date-full'
    | 'date'
    | 'bool'
    | 'amount'
    | 'percent'
    | 'relation'
    | 'chip'
    | 'phone'
    | 'email'
    | 'recursive'
    | 'HTML'
    | 'HTMLText'
    | 'select'
    | 'custom'
    | 'pargraphe'
    | 'pargraphe2'
    | 'float'
    | 'search';

export const pageFormBanType: Schema['type'][] = [
    'subSchema',
    'recursive',
    'relation',
    'custom',
];
export const pageFilterBanList: Schema['type'][] = [
    'subSchema',
    'recursive',
    'relation',
];

export interface PageConfig {
    config: Config;
    schemas: Schema[];
    redirect?: Redirect;
    relations?: Relation[];
}

export interface Relation {
    labelName: string; // name of the label for the ui
    postKeyDb: string; // name of the key in the db to update the relation
    getKeyInData: string; // name of the key to find the correct data in the data object
    getApiUrlOptions: string; // url of the api to get the select options data
    displayFieldOption: string[]; // field to display in the select (ex: raisonSociale, nom, prenom, etc...)
    many?: boolean; // if the relation is many to many
    asVersion?: boolean; // if the relation as a version system
}

export interface Config {
    apiUrl: string;
    modelName: string;
    itemName: string;
    options: optionItem[];
    banExport?: DataExport[];
    defaultOrder?: { [key: string]: 'asc' | 'desc' };
    secondarySearch?: string;
    redirectTo?: string;
    customFilter?: { [key: string]: boolean };
    // if you need custom element for the table you need to add the identifier for the schema you want to custom
    indentifier?: string;
    // information display in the update form
    formInfos?: Schema[];
    validatedRowRef?: string;
}

export type FormInfos = {
    ref: string;
    title: string;
};

export interface Schema {
    // connection to db
    ref: string;

    // display
    title?: string;
    style?: style;
    type?: DataType;

    // beavior
    ban?: optionItem[];
    form?: Form;
    search?: boolean;

    valueMap?: ValueMap[];
    subSchema?: SubSchema;
    redirect?: Redirect;
}

export interface SubSchema {
    display: 'table' | 'drawer' | 'api';
    multi: boolean;
    itemName: string;
    config: Config;
    schemas: Schema[];
}
// for the redirect to work you need to add the the correct object in the modelFind array
// in the corresponding service.ts file
export interface Redirect {
    from?: string;
    to: string;
    itemName: string;
}

export interface ValueMap {
    value: string;
    color: string;
    icon?: IconName;
}

export interface Form {
    display?: boolean;
    canBeEmpty?: boolean;
    requier?: boolean;
    defaultValue?: boolean | string | number;
    select?: FormSelect;
    // custom edition for the field you need to add a unique id to the field
    custom?: string;
}

export interface FormSelect {
    apiUrl: string;
    fieldName: string;
    fieldResult: string;
    type: 'select' | 'autocomplete' | 'search';
}

export type RowColor = { value: string; color: string };
export interface style {
    color?: string;
    icon?: IconName;
    width?: number;
    fixed?: boolean;
    isDisplayInTable?: boolean;
}
