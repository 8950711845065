import * as React from 'react';
import { Table, Sheet, Checkbox, Typography, Box, CircularProgress, Button, Divider, ListDivider, ListItemDecorator, MenuItem, Menu, IconButton, MenuButton, Dropdown } from '@mui/joy';
import { camelCaseToText } from '@app/system/utils/basic';
import { PageConfig } from '@app/pages'
import RenderAggregateRow from './RenderAggregateRow';
import { RenderRow } from './RenderRow';
import { AppIcon, IconName } from '@app/components/AppIcon';
import { UpdateForm } from '@app/main/forms/UpdateForm';
import { AddForm } from '@app/main/forms/AddForm';
import AppLoading from '@app/components/AppLoading';
import VersionHistory from './VersionHistory';
import { useNavigate } from 'react-router-dom';
import UpdateFormCustom from '@app/main/forms/UpdateFormCustom';
import { useEffect } from 'react';
import SubRelationForm from '@app/main/forms/components/SubRelationForm';
import RelationsForm from '@app/main/forms/RelationsForm';

export interface TableProps {
    pageConfig: PageConfig;
    data: any[];
    isLoaded: boolean;
    aggregate?: any;
    selected?: string[];
    setSelected?: (value: any) => void;
    onSuccess: () => void,
    totalCount?: number;
    handleGetAllData?: (e:null|number) => any,
    isSub?: boolean,
}

export const OrderTable = ({ pageConfig, data, isLoaded, aggregate, selected, setSelected, onSuccess, totalCount, handleGetAllData, isSub = false}: TableProps) => {
    const schemas = pageConfig.schemas
    const config = pageConfig.config
    const [editedRow, setEditedRow] = React.useState<any>(null);
    const [openEdit, setOpenEdit] = React.useState<boolean>(false);
    const [openEditCustom, setOpenEditCustom] = React.useState<boolean>(false);
    const [openAdd, setOpenAdd] = React.useState<boolean>(false);
    const [openRelation, setOpenRelation] = React.useState<boolean>(false);
    const [loadingCheck, setLoadingCheck] = React.useState<boolean>(false);

    const [openVersionHistory, setOpenVersionHistory] = React.useState<boolean>(false);

    const handleMainSelectCheck = async (checked: boolean) => {
        if(!setSelected || !selected)return;
        if(selected.length === 0 && handleGetAllData !== undefined){
            setLoadingCheck(true);
            const limit = 1000;
            const allData = await handleGetAllData(limit);
            setSelected(allData.tableData.map((row: any) => row.id));
            setLoadingCheck(false);
        }else{
            setSelected([]);
        }
    }

    const DisplayLastColumn = () => {  
        if(config.options.includes('update') || config.options.includes('updateCustom')) return true;
        if(config.redirectTo) return true;
        return false;
    }
    const displayLastColumn = DisplayLastColumn();
    const navigate = useNavigate();

    type ButtonType = { 
        type: 'update' | 'updateCustom' | 'relations' | 'redirect',
        label: string,
        icon: IconName,
        onClick: (row: any) => void
    }
    const [buttons, setButtons] = React.useState<ButtonType[]>([])

    useEffect(() => {
        const allButtons: ButtonType[] = [] 
        if(config.options.includes('update')) allButtons.push({type: 'update', label: 'Edition', icon: 'PencilIcon', onClick: (row) => {setEditedRow(row); setOpenEdit(true)}})
        if(config.options.includes('updateCustom')) allButtons.push({type: 'updateCustom', label: 'Actions', icon: 'Cog6ToothIcon', onClick: (row) => {setEditedRow(row); setOpenEditCustom(true)}})
        if(pageConfig.relations) allButtons.push({type: 'relations', label: 'Relations', icon: 'LinkIcon', onClick: (row) => {setEditedRow(row); setOpenRelation(true)}})
        if(config.redirectTo) allButtons.push({type: 'redirect', label: 'Redirection', icon: 'ArrowRightIcon', onClick: (row) => navigate('data' + config.redirectTo + '?filters=%5B%7B"filter_by"%3A"id"%2C"filter_type"%3A"equal"%2C"filter_value"%3A"' + row.id + '"%7D%5D&page=1')})
        setButtons(allButtons)
    }, [])
    
    return (
        <React.Fragment>
            <Sheet
                variant="outlined"
                className={isSub ? "" : "h-screen"}
                sx={[
                    {
                    '--TableCell-height': '40px',
                    '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                    '--Table-firstColumnWidth': '60px',
                    '--TableRow-stripeBackground': 'rgba(0 0 0 / 0.04)',
                    '--TableRow-hoverBackground': 'rgba(0 0 0 / 0.08)',
                    overflow: 'auto',
                    backgroundSize:
                        '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'local, local, scroll, scroll',
                    backgroundPosition:
                        'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
                    backgroundColor: 'background.surface',
                    borderRadius:'sm',
                    },
                    displayLastColumn && data.length !== 0 && {
                        '--Table-lastColumnWidth': '60px',
                    }
                ]}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    stickyFooter
                    borderAxis="bothBetween"
                    sx={[
                        {
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                        '& tr > *:first-of-type': {
                            position: 'sticky',
                            left: 0,
                            boxShadow: '1px 0 var(--TableCell-borderColor)',
                            bgcolor: 'background.surface',
                          },
                          '--TableCell-selectedBackground': (theme) =>
                            theme.vars.palette.primary.softBg,
                          '& thead th:nth-of-type(1)': {
                            width: '40px',
                          },
                          '--TableCell-validatedBackground': (theme) =>
                            theme.vars.palette.success.softBg,
                             
                        },
                        displayLastColumn && {
                              '& tr > *:last-child': {
                            position: 'sticky',
                            right: 0,
                            bgcolor: 'var(--TableCell-headBackground)',
                          },
                        },
                    ]}
                >
                    <thead>
                        <tr>
                            {data && selected && setSelected ?
                                <th style={{ width: 'var(--Table-firstColumnWidth)', zIndex: '11', textAlign: 'center', padding: '12px 6px' }}>
                                    {!loadingCheck ?
                                    <Checkbox
                                        size="sm"
                                        indeterminate={selected.length > 0 && selected.length !== totalCount}
                                        checked={selected.length === totalCount}
                                        onChange={(event) => {handleMainSelectCheck(event.target.checked)}}
                                        color={selected.length > 0 || selected.length === data.length
                                            ? 'primary'
                                            : undefined}
                                        sx={{ verticalAlign: 'text-bottom' }}
                                    />
                                    :
                                    <AppLoading />
                                    }
                                </th> 
                                : 
                                <th style={{ width: 'var(--Table-firstColumnWidth)', zIndex: '11', textAlign: 'center', padding: '12px 6px' }}>
                                    <Checkbox
                                        size="sm"
                                        checked={false}
                                        disabled={true}
                                        sx={{ verticalAlign: 'text-bottom' }} />
                                </th>
                            }
                            {schemas.map((schema, index) => (
                                schema.style?.isDisplayInTable !== false &&
                                <React.Fragment key={index}>
                                    {schema.type === 'recursive' ? schema.subSchema && schema.subSchema.schemas.map((schema, subIndex) => (
                                        <React.Fragment key={index.toString() + subIndex.toString()}>
                                            <th className={schema?.style?.fixed ? 'sticky top-0 left-0 border-x-2 border-gray-500' : ''} style={{ width: schema?.style?.width || '240px', padding: '12px 6px' }}>
                                                {schema.title ? schema.title : camelCaseToText(schema.ref)}
                                            </th>
                                        </React.Fragment>
                                    ))
                                        :
                                        <React.Fragment key={index}>
                                            <th 
                                                className={schema?.style?.fixed ? 'sticky top-0 left-0 border-x-2 border-gray-500' : ''} 
                                                style={
                                                    { 
                                                        zIndex: schema?.style?.fixed ? '11' : '', 
                                                        width: schema?.style?.width || '240px', 
                                                        padding: '12px 6px' 
                                                    }
                                                }
                                            >
                                                {schema.title ? schema.title : camelCaseToText(schema.ref)}
                                            </th>
                                        </React.Fragment>
                                    }


                                </React.Fragment>
                            ))}
                            <th
                                className=''
                                aria-label="last"
                                style={{ width: 'var(--Table-lastColumnWidth)', textAlign: 'center', padding: '12px 6px' }}
                            >   
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length > 0 && data.map((row, colIndex) => (
                        
                           
                            <tr key={colIndex}  
                            
                                style={
                                    // TableCell-validatedBackground
                                    selected && selected.includes(row.id)
                                    ? ({
                                        '--TableCell-dataBackground':
                                            'var(--TableCell-selectedBackground)',
                                        '--TableCell-headBackground':
                                            'var(--TableCell-selectedBackground)',
                                        } as React.CSSProperties)
                                    : config.validatedRowRef && row[config.validatedRowRef] == true ? ({
                                        '--TableCell-dataBackground':
                                            'var(--TableCell-validatedBackground)',
                                        '--TableCell-headBackground':
                                            'var(--TableCell-validatedBackground)',
                                        } as React.CSSProperties) : ({
                                        '--TableCell-dataBackground': 'var(--joy-palette-background-surface)',
                                    } as React.CSSProperties)
                                }
                            >
                                <td style={{ textAlign: 'center', width: 120, zIndex: '2' }}>
                                    {selected && setSelected ?
                                        <Checkbox
                                            size="sm"
                                            checked={selected.includes(row.id)}
                                            color={selected.includes(row.id) ? 'primary' : undefined}
                                            onChange={(event) => {
                                                setSelected((ids: any) => event.target.checked
                                                    ? ids.concat(row.id)
                                                    : ids.filter((itemId: any) => itemId !== row.id)
                                                );
                                            }}
                                            slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                            sx={{ verticalAlign: 'text-bottom' }} />
                                            :
                                        <Checkbox
                                            size="sm"
                                            checked={false}
                                            disabled
                                            slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                            sx={{ verticalAlign: 'text-bottom' }} />
                                    }
                                </td> 

                                <RenderRow schemas={schemas} data={row} id={row.id} onSuccess={onSuccess} />

                                {displayLastColumn && 
                                    <td style={{ textAlign: 'center', zIndex: '2' }}>
                                        {buttons.length === 1 ? 
                                            <>
                                                {buttons.map((button) => (
                                                    <Button key={button.type} onClick={() => button.onClick(row)} color="primary" size="sm" variant="plain">
                                                        <AppIcon icon={button.icon} />
                                                    </Button>
                                                ))}
                                            </>
                                            : 
                                            <> 
                                                <Dropdown>
                                                    <MenuButton
                                                        slots={{ root: IconButton }}
                                                        slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                                                    >
                                                        <AppIcon icon='EllipsisHorizontalIcon' />
                                                    </MenuButton>
                                                    <Menu placement="bottom-end">
                                                        {buttons.map((button) => (
                                                            <MenuItem color="primary" key={button.type} onClick={() => button.onClick(row)}>
                                                                <ListItemDecorator>
                                                                    <AppIcon icon={button.icon} />
                                                                </ListItemDecorator>{' '}
                                                                {button.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                </Dropdown>
                                            </>
                                        }
                                    </td>
                                }
                                
                                
                            </tr>
                        ))}
                        
                    </tbody>
                
                    <tfoot>
                        {data && data.length > 0 && aggregate && <RenderAggregateRow schemas={schemas} data={aggregate._sum} />}
                    </tfoot>


                    
                </Table>
                
                <div className='sticky top-0 left-0'>
                    <Divider />
                    {data && data.length <= 0 &&
                    <Box className="flex justify-center items-center w-full h-254">
                        <div className="py-24">
                            {isLoaded ?
                                <>
                                    <Typography fontSize='xl'>Aucunes données disponibles</Typography>
                                </>
                                :
                                <CircularProgress
                                    color="neutral"
                                    sx={{
                                        "--CircularProgress-progressThickness": "2px",
                                        "--CircularProgress-trackThickness": "2px",
                                        "--CircularProgress-size": "22px"
                                    }}
                                />
                            }
                        </div>
                    </Box>
                }
                </div>
                {openEditCustom && <UpdateFormCustom onSuccess={onSuccess} open={openEditCustom} onClose={() => {setEditedRow(null); setOpenEditCustom(false)}} pageConfig={pageConfig} data={editedRow}/>}
                {openEdit && <UpdateForm onSuccess={onSuccess} open={openEdit} onClose={() => {setEditedRow(null); setOpenEdit(false) }} pageConfig={pageConfig} data={editedRow}/>}
                {openAdd && <AddForm onSuccess={onSuccess} open={openAdd} onClose={() => setOpenAdd(false)} pageConfig={pageConfig} data={data}/>}
                {openVersionHistory && <VersionHistory modelId={editedRow.id} pageConfig={pageConfig} open={openVersionHistory} onClose={() => setOpenVersionHistory(false)}/>}
                {openRelation && <RelationsForm pageConfig={pageConfig} data={editedRow} open={openRelation} onClose={() => {setEditedRow(null); setOpenRelation(false)}} onSuccess={onSuccess}/>}
               
            </Sheet>
        </React.Fragment>
    );
}

export default OrderTable;
