import { PageConfig } from '../type';

export const parrainageViewSchema: PageConfig = {
    config: {
        modelName: 'parrainages',
        itemName: 'un parrainage',
        apiUrl: '/view-parrainages',
        options: ['filter', 'order'],
        banExport: ['pdf'],
        defaultOrder: { ['facture/numeroFacture']: 'asc' },
        secondarySearch: 'Numero Facture',
        redirectTo: '/parrainages',
    },

    schemas: [
        {
            style: { width: 200 },
            ref: 'personneMorale/raisonSociale',
            title: 'Raison Sociale',
            type: 'HTMLText',
            search: true,
        },
        { style: { width: 140 }, ref: 'titre', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'prenom', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'nom', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'prenom2', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'nom2', ban: ['order', 'filter'] },
        {
            style: { width: 200 },
            ref: 'personneMorale/adresse/ligne1',
            title: 'Adresse',
        },
        {
            style: { width: 140 },
            ref: 'personneMorale/adresse/casePostal',
            title: 'Case Postale',
            type: 'amount',
        },
        {
            style: { width: 140 },
            ref: 'personneMorale/adresse/NPA',
            title: 'Npa',
        },
        {
            style: { width: 140 },
            ref: 'personneMorale/adresse/region',
            title: 'Localite',
        },
        {
            style: { width: 140 },
            ref: 'telephone',
            ban: ['order', 'filter'],
        },
        {
            style: { width: 140 },
            ref: 'telephone2',
            ban: ['order', 'filter'],
        },
        {
            style: { width: 140 },
            ref: 'raisonSocialePj',
            title: 'Raison sociale PJ',
            search: true,
        },
        {
            style: { width: 240 },
            ref: 'facture/libelleFacture',
            title: 'Libelle Facture',
            type: 'pargraphe2',
            search: true,
        },
        { style: { width: 200 }, ref: 'coordonneesCorrespondance' },
        { style: { width: 140 }, ref: 'accordDe', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'dateAccord', type: 'date' },
        { style: { width: 280 }, ref: 'email', ban: ['order', 'filter'] },
        { style: { width: 140 }, ref: 'accuseDeReception', type: 'bool' },
        { style: { width: 240 }, ref: 'noteInterne', type: 'HTML' },
        { style: { width: 140 }, ref: 'publicite' },
        { style: { width: 140 }, ref: 'reconduction', type: 'bool' },
        { style: { width: 140 }, ref: 'annonceOk', type: 'bool' },
        {
            style: { width: 140, fixed: true },
            ref: 'facture/numeroFacture',
            title: 'Numero Facture',
            search: true,
        },
        {
            style: { width: 140 },
            ref: 'montantPromesse',
            type: 'money',
            title: 'Montant promesse',
            search: true,
        },
        { style: { width: 140 }, ref: 'mandat/ref', title: 'Id Club' },
        { style: { width: 140 }, ref: 'mandat/club/nom', title: 'Nom Club' },
        {
            style: { width: 200 },
            ref: 'facture/montantPayeHorsTva',
            type: 'money',
            title: 'Montant paye',
        },
        {
            style: { width: 140 },
            ref: 'facture/datePaiement',
            type: 'date',
            title: 'Date Paiement',
        },
        {
            style: { width: 140 },
            title: 'Paiement',
            ref: 'facture/paiement',
            type: 'bool',
        },
        {
            style: { width: 340 },
            ref: 'noteLn',
            title: 'Commentaires LN',
            type: 'pargraphe2',
        },
        { style: { width: 140 }, ref: 'datesRappels', type: 'date' },
        {
            style: { width: 140 },
            ref: 'collaborateur/ref',
            title: 'Id Collaborateur',
        },
        { style: { width: 140 }, ref: 'tauxCommission', type: 'percent' },
        { style: { width: 140 }, ref: 'commissionPayee', type: 'money' },
        { style: { width: 140 }, ref: 'dateCommissionPayee', type: 'date' },
        { style: { width: 140 }, ref: 'soldePaye', type: 'money' },
        { style: { width: 140 }, ref: 'dateSoldePaye', type: 'date' },
        { style: { width: 140 }, ref: 'ristourneAcvfPayee', type: 'money' },
        { style: { width: 140 }, ref: 'dateRistourneAcvfPayee', type: 'date' },
        { style: { width: 140 }, ref: 'potentielId', type: 'amount' },
        { style: { width: 140 }, ref: 'verifiedLeadId', type: 'amount' },
        { style: { width: 140 }, ref: 'annulation', type: 'bool' },
        { style: { width: 140 }, ref: 'dateAnnulation', type: 'date' },
        { style: { width: 140 }, ref: 'storno', type: 'bool' },
        { style: { width: 140 }, ref: 'dateStorno', type: 'date' },
        { style: { width: 140 }, ref: 'stornoReactive', type: 'bool' },
        { style: { width: 140 }, ref: 'dataStornoReactive', type: 'date' },
    ],
};

export default parrainageViewSchema;
