import { PageConfig } from '../type';

export const listeNoireUserSchema: PageConfig = {
    config: {
        indentifier: 'listeNoireUserSchema',
        modelName: 'listeNoires',
        itemName: 'une listeNoire',
        apiUrl: '/liste-noire',
        options: ['filter', 'order', 'update'],
        banExport: ['pdf'],
        formInfos: [
            {
                ref: 'parrainage/facture/numeroFacture',
                title: 'Numeros de facture',
            },
            { ref: 'collaborateur', title: 'Raison social' },
            { ref: 'personnePhysique/nom', title: 'Personne physique nom' },
        ],
        validatedRowRef: 'done',
    },

    schemas: [
        {
            type: 'bool',
            style: {
                width: 140,
            },
            ref: 'done',
            title: 'Fait',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/facture/numeroFacture',
            title: 'Numero facture',
            ban: ['update'],
        },
        {
            style: { width: 200 },
            ref: 'parrainageView/entreprise',
            title: 'Coordonnées',
            ban: ['update', 'filter', 'order', 'search'],
        },
        {
            style: { width: 200 },
            ref: 'parrainage/facture/libelleFacture',
            title: 'Libellé Facture',
            ban: ['update'],
        },
        {
            style: { width: 220 },
            ref: 'parrainage/coordonneesCorrespondance',
            title: 'Coordonnees Correspondance',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainageView/telephone',
            title: 'Telephone',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainageView/accordDe',
            title: 'Accord De',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/facture/dateFacture',
            title: 'Date Facture',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/dateAccord',
            title: 'Date Accord',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 240 },
            ref: 'parrainageView/email',
            title: 'Email',
            ban: ['update'],
        },
        {
            style: { width: 180 },
            ref: 'parrainage/noteInterne',
            title: 'Info Interne',
            ban: ['update'],
            type: 'HTML',
        },
        {
            style: { width: 140 },
            ref: 'parrainage/publicite',
            title: 'Publicite',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/reconduction',
            title: 'Reconduction',
            type: 'bool',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/mandat/club/nom',
            title: 'Club',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/mandat/ref',
            title: 'Id Club',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/facture/numeroFacture',
            title: 'Numero facture',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/montantPromesse',
            title: 'CHF promesse',
            type: 'money',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/facture/montantPaye',
            title: 'CHF payé',
            type: 'money',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/datesRappels',
            title: 'Dates rappels',
            type: 'date',
            ban: ['update'],
        },
        {
            style: { width: 140 },
            ref: 'parrainage/noteLn',
            title: 'Commentaires LN',
            type: 'pargraphe2',
            ban: ['update'],
        },

        // hidden fields
        {
            style: { width: 140, isDisplayInTable: false },
            ref: 'done',
            title: 'Fait',
            type: 'bool',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 200, isDisplayInTable: false },
            ref: 'dateAppelHeure',
            type: 'date',
            title: "Date de l'appel heure",
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 140, isDisplayInTable: false },
            ref: 'dateProchainAppel',
            title: 'Date prochain appel',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 140, isDisplayInTable: false },
            ref: 'laisseMessageLe',
            title: 'Laisse message le',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 200, isDisplayInTable: false },
            ref: 'annulerLe',
            title: 'Annuler le ….',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 140, isDisplayInTable: false },
            ref: 'renvoiRappel',
            title: 'Renvoi rappel',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 200, isDisplayInTable: false },
            ref: 'paiementPrevuLe',
            title: 'Paiement prevu le',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 350, isDisplayInTable: false },
            ref: 'diversInfos',
            title: 'Divers et infos / Annulation preciser la raison',
            type: 'pargraphe2',
            ban: ['filter', 'order', 'search'],
        },
        {
            style: { width: 140, isDisplayInTable: false },
            ref: 'rappelLe',
            title: 'Rappel le :',
            type: 'date',
            ban: ['filter', 'order', 'search'],
        },
    ],
};

export default listeNoireUserSchema;
