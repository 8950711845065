import { PageConfig } from '../type';

export const mandatSchema: PageConfig = {
    config: {
        modelName: 'Mandats',
        itemName: 'un mandat',
        apiUrl: '/mandats',
        options: [
            'create',
            'update',
            'updateMany',
            'delete',
            'filter',
            'order',
        ],
    },
    schemas: [
        {
            style: { width: 80, isDisplayInTable: false },
            ref: 'clubId',
            form: {
                requier: true,
                select: {
                    apiUrl: 'clubs',
                    fieldName: 'nom',
                    fieldResult: 'id',
                    type: 'autocomplete',
                },
            },
        },
        // { style: { width: 80 }, ref: 'ref', title:'ID Club', type: 'ref', form:{requier: true} },
        {
            style: { width: 160 },
            ref: 'id',
            type: 'ref',
            form: { display: false },
        },
        {
            style: { width: 180 },
            ref: 'ref',
            title: 'Ref Mandat (ID Club)',
            type: 'ref',
            search: true,
            form: { requier: true },
        },
        {
            style: { width: 160 },
            ref: 'club/id',
            type: 'ref',
            form: { display: false },
        },
        {
            style: { width: 160 },
            ref: 'club/nom',
            type: 'ref',
            form: { display: false },
        },
        {
            style: { width: 120 },
            ref: 'dateAccord',
            type: 'date',
            form: { requier: true },
        },
        { style: { width: 160 }, ref: 'notes', type: 'note' },
        { style: { width: 140 }, ref: 'dateDebutAquisition', type: 'date' },
        { style: { width: 140 }, ref: 'dateFinAcquisition', type: 'date' },
        { style: { width: 100 }, ref: 'budget', type: 'money', search: true },
        { style: { width: 60 }, ref: 'acvf', type: 'bool' },
        {
            style: { width: 140 },
            ref: 'dateArretDemande',
            type: 'date',
            ban: ['create'],
        },
        {
            style: { width: 140 },
            ref: 'dateCloture',
            type: 'date',
            ban: ['create'],
        },
        { style: { width: 140 }, ref: 'cloture', type: 'bool' },
        {
            style: { width: 140 },
            ref: 'parrainage',
            title: 'Parrainages',
            type: 'relation',
            redirect: { itemName: 'parrainage', to: 'parrainages' },
        },
        {
            style: { width: 140 },
            ref: 'commandeMateriel',
            title: 'Commandes',
            type: 'relation',
            redirect: { itemName: 'commande', to: 'commandes' },
        },
        {
            style: { width: 140 },
            ref: 'gestionDeLogo',
            type: 'percent',
            form: { defaultValue: 5 },
        },
        {
            style: { width: 200 },
            ref: 'fraisDeServiceAquisitionMateriel',
            type: 'percent',
            form: { defaultValue: 25 },
        },
        {
            style: { width: 200 },
            title: 'Ristourne',
            ref: 'isRistourne',
            type: 'bool',
            form: { defaultValue: false },
        },
        {
            style: { width: 200 },
            ref: 'fraisDeServiceRistourne',
            type: 'percent',
            form: { defaultValue: 50 },
        },
        {
            style: { width: 200 },
            ref: 'fraisDeServiceAutres',
            type: 'percent',
            form: { defaultValue: 50 },
        },
    ],
};
