import axios from "../../../config/axios.config";
import { AppModal } from "@app/components/AppModal";
import AppAlert from "@app/components/AppAlert";
import { Checkbox, FormLabel } from "@mui/joy";
import RequestField from "../components/RequestField";
import { useState } from "react";

type props = {
    onSuccess: () => void,
    idToUpdate: number[],
    open: boolean,
    onClose: () => void,
} 

export const ValidateListeNoire = ({ idToUpdate, onSuccess, open, onClose }: props) => {
    // Liste Noire Affecté à
    const [listeNoireAffecteId, setListeNoireAffecteId] = useState<number>();
    const [validate, setValidate] = useState<boolean>(false);
    const submit = () => {
        if(listeNoireAffecteId){
            idToUpdate.forEach(id => {
                axios.patch(`/liste-noire/${id}/affecter`, {
                    collaborateurId: listeNoireAffecteId
                }).then(resp => {
                    onSuccess();
                    onClose();
                })
            })
        }
        if(validate){
            console.log(' validate->', validate)
            
            idToUpdate.forEach(id => {
                axios.patch(`/liste-noire/${id}/validate`).then(resp => {
                    onSuccess();
                    onClose();
                })
            })
        }
        setListeNoireAffecteId(undefined);
        setValidate(false);
    }
    
    return (
        <AppModal 
            handleSubmit={submit} 
            icon='PencilSquareIcon' 
            title={`Modifications liste noire`} 
            submitText="Modifier" 
            submitIcon="PencilSquareIcon"
            open={open} 
            onClose={onClose} 
            >
                <div className="flex flex-col min-w-[400px]">
                    {/* <FormLabel className="space-x-2 flex flex-col items-center w-full"> */}
                        <span className="text-sm">Affecter à</span>
                        <RequestField
                            apiUrl='/user-login/collaborateur'
                            keyRelation='userName'
                            keyResult='id'
                            type='select'
                            onChange={(value) => setListeNoireAffecteId(Number(value))}
                            value={listeNoireAffecteId ? listeNoireAffecteId : ''}
                        />
                    {/* </FormLabel> */}
                
                    <FormLabel className="space-x-2 py-8">
                        <Checkbox checked={validate} onChange={() => setValidate(!validate)} />
                        <span>Valider les listes noires</span>
                    </FormLabel>
                    {validate && <AppAlert message={{
                        color: 'warning',
                        title: "Validation de les listes noires",
                        text: "Voulez-vous valider les listes noires ?"
                    }} />}
                </div>
        </AppModal>
    )
}
export default ValidateListeNoire;


