import Alert from '@mui/joy/Alert';
import AspectRatio from '@mui/joy/AspectRatio';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { AppIcon, IconName } from './AppIcon';
import { ColorPaletteProp } from '@mui/joy';

export interface MessageAlert{
  color: ColorPaletteProp
  title: string
  text: string
}


interface props{
  message: MessageAlert
}

interface iconRelation{
  primary: IconName,
  success: IconName,
  danger: IconName,
  neutral: IconName,
  warning: IconName
}



export default function AppAlert({message}:props) {
  const icon: iconRelation = {
    primary: 'InformationCircleIcon',
    success: 'CheckIcon',
    danger: 'XMarkIcon',
    neutral: 'CheckIcon',
    warning: 'ExclamationTriangleIcon'
  }
  return (
    <Stack className="w-full" spacing={2} >
      <Alert
        size="lg"
        color={message.color}
        variant="soft"
        invertedColors
        startDecorator={
          <AspectRatio
            variant="solid"
            ratio="1"
            sx={{
              minWidth: 40,
              borderRadius: '50%',
              boxShadow: '0 2px 12px 0 rgb(0 0 0/0.2)',
            }}
          >
            <div>
                <AppIcon icon={icon[message.color]}/>
            </div>
          </AspectRatio>
        }
        sx={{ alignItems: 'flex-start', overflow: 'hidden' }}
      >
        <div>
          <Typography level="body-sm">
            {message.text}
          </Typography>
        </div>
        
      </Alert>
    </Stack>
  );
}
