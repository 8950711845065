import { AppIcon } from "@app/components/AppIcon";
import { AppModal } from "@app/components/AppModal";
import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl";
import { capitalizeFirstLetter } from "@app/system/utils/basic";
import { Button, FormControl, FormLabel, Input, Option, Select } from "@mui/joy"
import { useEffect, useState } from "react";

type props = {
    filterBy: string
}



const FilterDateMonth = ({filterBy}:props) => {

    const { autoUpdateFilter} = useAppFiltersUrl()
    const [openModal, setOpenModal] = useState(false)
    const [selectedDate, setSelectedDate] = useState<string|null>() 


    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleSubmit = () => {
        setOpenModal(false)
        if (selectedDate) {
            autoUpdateFilter(
                {   
                    filter_by: filterBy,
                    filter_type: 'custom',
                    filter_value: formatDate(selectedDate),
                },
            );
            

        }else{
            autoUpdateFilter(
                {   
                    filter_by: filterBy,
                    filter_type: 'custom',
                    filter_value: '',
                },
            );
        }

    };

    const handleChangeSelectedDate = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
        setSelectedDate(newValue)
    };

    return (
        <>
            <FormControl  size="sm">
                <FormLabel>Statut en date du</FormLabel>
                <Button variant="soft" color="neutral" size="sm" startDecorator={<AppIcon icon="CalendarIcon" />} onClick={() => setOpenModal(true)}>Date</Button>
            </FormControl>

            <AppModal open={openModal} onClose={() => setOpenModal(false)} handleSubmit={handleSubmit} title="Statut en date du">
                <FormControl size="sm">
                    <FormLabel>Statut en date du</FormLabel>
                <Input 
                    type="date" 
                    value={selectedDate || ''} 
                    onChange={(e) => handleChangeSelectedDate(e, e.target.value)}
                />
                <Button variant="soft" color="danger" size="sm" startDecorator={<AppIcon icon="XMarkIcon" />} onClick={() => setSelectedDate(null)}>Supprimer</Button>
            </FormControl>
            </AppModal>
        </>
    )
    
}

export default FilterDateMonth