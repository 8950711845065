import { useAppFiltersUrl } from "@app/system/hooks/useAppFiltersUrl";
import { capitalizeFirstLetter } from "@app/system/utils/basic";
import { FormControl, FormLabel, Option, Select } from "@mui/joy"
import { useEffect, useState } from "react";

type props = {
    filterBy: string
    defautCurrentDate?: boolean
}

type MonthItem = {
    title: string,
    number: number, 
}

const monthList: MonthItem[] = [
    {title: 'janvier', number: 1},
    {title: 'fevrier', number: 2},
    {title: 'mars', number: 3},
    {title: 'avril', number: 4},
    {title: 'mai', number: 5},
    {title: 'juin', number: 6},
    {title: 'juillet', number: 7},
    {title: 'aout', number: 8},
    {title: 'septembre', number: 9},
    {title: 'octobre', number: 10},
    {title: 'novembre', number: 11},
    {title: 'decembre', number: 12},
]

const FilterDateMonth = ({filterBy, defautCurrentDate = true}:props) => {

    const { autoUpdateFilter} = useAppFiltersUrl()

    const currentMonth = new Date().getMonth()
    const yearStart = 2017;
    const currentYear = new Date().getFullYear()
    const [yearList, setYearsList] = useState<number[]>([])

    const [selectedMonth, setSelectedMonth] = useState<number|null>()
    const [selectedYear, setSelectedYear] = useState<number|null>()


    useEffect(() => {
        const tempList = []
        for(let i=yearStart; i <= currentYear; i++ ){
            tempList.push(i)
        }
        if(defautCurrentDate){
            setSelectedMonth(currentMonth + 1)
            setSelectedYear(currentYear)
        }
        setYearsList(tempList)
    }, [])

    useEffect(() => {

        if(selectedYear !== undefined && selectedMonth !== undefined){
            autoUpdateFilter(
                {   
                    filter_by: filterBy,
                    filter_type: 'select_month_metier',
                    filter_value: `${selectedMonth}-${selectedYear}`,
                },
            )
        }

    }, [selectedMonth, selectedYear])

    const handleChangeSelectedMonth = (
        event: React.SyntheticEvent | null,
        newValue: number | null,
    ) => {
        setSelectedMonth(newValue)
    };

    const handleChangeSelectedYear = (
        event: React.SyntheticEvent | null,
        newValue: number | null,
    ) => {
        setSelectedYear(newValue)
    };

    return (
        <>
            <FormControl size="sm">
                <FormLabel>Mois</FormLabel>
                <Select value={selectedMonth} onChange={handleChangeSelectedMonth}>
                    {monthList.map((monthItem: MonthItem, index: number) => (
                        <Option value={monthItem.number} key={index}>{capitalizeFirstLetter(monthItem.title)}</Option>
                    )) }
                </Select>
            </FormControl>

            <FormControl size="sm">
                <FormLabel>Années</FormLabel>
                <Select value={selectedYear} onChange={handleChangeSelectedYear}>
                    {yearList.map((year: number) => (
                        <Option key={year} value={year}>{year}</Option>
                    )) }
                </Select>
            </FormControl>
        </>
    )
    
}

export default FilterDateMonth