import { AppModal } from "@app/components/AppModal"
import { useEffect, useState } from "react"
import { AppIcon } from "@app/components/AppIcon"
import { Button, Divider, FormControl, FormLabel, IconButton, Input, Option, Select, Sheet, Typography } from "@mui/joy"
import RenderField from "@app/main/forms/components/RenderField"
import { Schema, PageConfig } from "@app/pages"
import { DataItem, useAppForm } from "@app/system/hooks/useAppForm"
import { adresseSchema } from "@app/pages/utils/adresse"
import RequestField from "@app/main/forms/components/RequestField"
import { createRequest, getIdRequest } from "@app/api/generic/generic"
import AppAlert from "@app/components/AppAlert"


type props = {
    onSuccess: () => void
}

const commandeLineSchema: PageConfig['schemas'] = [
    { style: { width: 200 }, ref: 'article', form:{requier: true} },
    { style: { width: 100 }, ref: 'quantite', type: 'amount', form:{requier: true} },
    { style: { width: 140 }, ref: 'couleur' },
    { style: { width: 140 }, ref: 'notes'},
    { style: { width: 100 }, ref: 'coutBrut', type: 'money', form:{requier: true} },
    { style: { width: 100 }, ref: 'coutNet', type: 'money', form:{requier: true} },
    { style: { width: 240 }, ref: 'marqueId', title: 'Marque', form:{requier: true} },
]

const AddCommandeClub = ({onSuccess}: props) => {
    
    const acceptedCommandeItem: Schema[] = commandeLineSchema
    
    const {createFormData, createDataReq} = useAppForm()
    const [open, setOpen] = useState<boolean>(false)
    const [openModalValidation, setOpenModalValidation] = useState<boolean>(false)
    const [dateCommand, setDateCommand] = useState<string>('')
    const [mandatType, setMandatType] = useState<string>('')
    const [mandatId, setMandatId] = useState<string>('')
    const [destinataire, setDestinataire] = useState<string>('')
    const [refInterneMv, setRefInterneMv] = useState<string>('')
    const [refCommandeFournisseur, setRefCommandeFournisseur] = useState<string>('')
    const [commandeClubRef, setCommandeClubRef] = useState<string>('')
    
    const [clubId, setClubId] = useState<any>()

    const [adresseInfo, setAdresseInfo] = useState<DataItem[]>([])
    const [commandeList, setCommandeList] = useState<DataItem[][]>([])

    const [errorMsg, setErrorMsg] = useState<string>('')

    const [selectedFournisseur, setSelectedFournisseur] = useState<string>('')

    useEffect(() => {
        const waitAndGet = async () => {
            const club = await getIdRequest('clubs/byMandatId', mandatId.replaceAll('/', '-'))
            setClubId(club.id)
        }
        waitAndGet()
    }, [mandatId])

    const InitCommandeForm = () => {
        setAdresseInfo(createFormData(adresseSchema.schemas))
        const newCommandeList: any[] = []
        newCommandeList.push(createFormData(acceptedCommandeItem))
        setCommandeList(newCommandeList)
    }
    useEffect(InitCommandeForm, [])

    const AddNewCommandeItem = () => {
        const newCommandeList: any[] = [... commandeList]
        newCommandeList.push(createFormData(acceptedCommandeItem))
        setCommandeList(newCommandeList)
    }

    const updateCommandeList = (index1: number, index2: number, value: string) => {
        const CommandeListCopy: any[] = [... commandeList]
        CommandeListCopy[index1][index2].value = value
        CommandeListCopy[index1][index2].edited = true
        setCommandeList(CommandeListCopy);
    }

    const deleteCommandeLigne = (index: number) => {
        const CommandeListCopy: any[] = [... commandeList]
        if(index === 0){
            CommandeListCopy[0].map((dataItem: DataItem) => {dataItem.value = '', dataItem.edited = false})
        }else{
            CommandeListCopy.splice(index, 1)
        }
        setCommandeList(CommandeListCopy);
    }

    const handleChangeMandatType = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
      ) => {
        if(newValue === null) return 
        setMandatId('')
        setClubId('')
        setMandatType(newValue)
    };

    const handleChangMandatId = (
        value: any
      ) => {
        setMandatId(value.toString())
    };

    const sendRequest = () => {
        const item = {
            dateCommande: dateCommand,
            destinataire: destinataire,
            mandatType: mandatType,
            mandatId: mandatId,
            commandeClubRef: commandeClubRef,
            refInterneMv: refInterneMv,
            refCommandeFournisseur: refCommandeFournisseur,
            fournisseurId: selectedFournisseur,
            clubId: clubId,
            commande: commandeList.map((item: DataItem[]) =>  createDataReq(item))
        } 

        createRequest(
            '/commandes/commandeClub', 
            item, 
            () => {
                onSuccess();
                setOpen(false); 
                resetForm()
            },
            (err) => {setErrorMsg(err)}
        )
    }

    const resetForm  = () => {
        InitCommandeForm()
        setDateCommand('')
        setMandatType('')
        setMandatId('')
        setCommandeClubRef('')
        setClubId('')
        setRefCommandeFournisseur('')
        setSelectedFournisseur('')
        setRefInterneMv('')
        setDestinataire('')
        setErrorMsg('')
    }

    return (
        <>
            <FormControl  size="sm">
                <FormLabel>Action</FormLabel>
                <Button
                onClick={() => setOpen(true)}
                variant="soft"
                size='sm'
                startDecorator={<AppIcon icon="ClipboardDocumentListIcon" />}
                color="primary"
                >
                    Commande Club
                </Button>
            </FormControl>

            <AppModal title="Commande Club" icon="ClipboardDocumentListIcon" submitText="Ajouter la commande" handleSubmit={() => setOpenModalValidation(true)} open={open} onClose={() => setOpen(false)}>
                <Sheet className="rounded-md -my-8 space-y-4 flex flex-col justify-center items-center max-w-254" variant="plain" color="neutral" sx={{ p: 4 }}>
                    <div className='flex w-full '>
                        <div className='w-full flex flex-wrap justify-start space-x-2'>
                            
                            <FormControl size="sm" className="w-44 ml-2">
                                <FormLabel>Type de mandat <Typography fontSize='sm' color="danger">*</Typography></FormLabel>
                                <Select value={mandatType} onChange={handleChangeMandatType}>
                                    <Option value="Mandat ACVF">ACVF</Option>
                                    <Option value="Mandat Matériel" >Mandat Matériel</Option>
                                    <Option value="Mandat FRG24" >Mandat FRG24</Option>
                                    <Option value="Commande Directe" >Commande Directe</Option>
                                </Select>
                            </FormControl>
                            <FormControl size="sm" className="w-44">
                                <FormLabel>Ref mandat <Typography fontSize='sm' color="danger">*</Typography></FormLabel>
                                {mandatType === "Commande Directe" ? 
                                    <Input disabled/>
                                    :
                                    <RequestField 
                                        disabled={!mandatType}
                                        filters={[
                                            {filter_by: 'ref', filter_type: 'start_with' ,filter_value: mandatType === "Mandat ACVF" ? 'ACVF-': mandatType === "Mandat FRG24" ? 'FRG24-' : '' }
                                        ]} 
                                        apiUrl="mandats" keyRelation='ref' keyResult='id' type='autocomplete' 
                                        onChange={(e) => handleChangMandatId(e)} value={parseInt(mandatId)}  
                                    />
                                }
                            </FormControl> 
                            <FormControl size="sm" className="w-44">
                                <FormLabel>Club <Typography fontSize='sm' color="danger">*</Typography></FormLabel>
                                <RequestField 
                                    apiUrl="clubs" keyRelation='nom' keyResult='id' type='autocomplete' 
                                    onChange={(e) => setClubId(e)} value={clubId}  
                                    disabled={mandatType !== "Commande Directe"}
                                />
                            </FormControl>
                            <FormControl size="sm" className="w-44">
                                <FormLabel>Ref Commande Club</FormLabel>
                                <Input value={commandeClubRef} onChange={(e) => setCommandeClubRef(e.target.value)}/>
                            </FormControl>

                            <FormControl size="sm" className="w-44">
                                <FormLabel>Ref Interne Mv</FormLabel>
                                <Input value={refInterneMv} onChange={(e) => setRefInterneMv(e.target.value)}/>
                            </FormControl>
                            
                            <FormControl size="sm" className="w-44">
                                <FormLabel >Date Commande  <Typography fontSize='sm' color="danger">*</Typography></FormLabel>
                                <Input value={dateCommand} onChange={(e) => setDateCommand(e.target.value)} type="date"/>
                            </FormControl>
                            <FormControl size="sm" className="w-44">
                                <FormLabel>Destinataire</FormLabel>
                                <Input value={destinataire} onChange={(e) => setDestinataire(e.target.value)}/>
                            </FormControl>

                            <FormControl size="sm" className="w-44">
                                <FormLabel>Ref Commande Fournisseur</FormLabel>
                                <Input value={refCommandeFournisseur} onChange={(e) => setRefCommandeFournisseur(e.target.value)}/>
                            </FormControl>

                            <FormControl size="sm" className="w-44">
                           
                                <FormLabel>Fournisseur  <Typography fontSize='sm' color="danger">*</Typography></FormLabel>
                                <RequestField apiUrl='/fournisseurs' keyRelation='nom' keyResult='id' type='select' onChange={(e) => setSelectedFournisseur(e)} value={selectedFournisseur ? selectedFournisseur : ''} />
                            </FormControl>
                           
                        </div>
                    </div>
                    <Divider />

                    {commandeList.map((commandeItem, index1) => (
                        <div className='flex w-full space-x-2 items-center' key={index1}>
                            {commandeItem.map((dataItem: DataItem, index2: number) => (
                                <div className='-my-4' key={index2}>
                                    <RenderField 
                                        dataItem={dataItem} 
                                        updateValue={(index: number, value: string) => updateCommandeList(index1, index2, value)} 
                                        index={index2}
                                        width={44}
                                        displayLable={index1 === 0}
                                    />
                                </div>
                            ))}
                            <FormControl className={index1 === 0 && 'hidden' || ''} size="sm" >
                                {index1 === 0 && <FormLabel>ㅤ</FormLabel>}
                                {<IconButton className="hidden pt-8" size='sm'  variant="soft" color={index1 === 0 ? "primary" : "danger"} onClick={() => deleteCommandeLigne(index1)} >
                                    <AppIcon icon={index1 === 0 ? 'ArrowPathIcon' : 'TrashIcon'}/>
                                </IconButton>}
                            </FormControl>  
                        </div>
                    ))}
                    
                    <Divider />
                    <div className="w-full flex justify-end">
                        <Button size='sm' variant="soft" color="primary" onClick={() => AddNewCommandeItem() } startDecorator={<AppIcon icon="PlusIcon" />} > 
                                Ajouter une ligne
                        </Button> 
                    </div>

                    {errorMsg && <AppAlert message={{color: 'danger', title: '', text: errorMsg}}/> } 

                </Sheet>
                
            </AppModal>
            <AppModal title='êtes-vous sûr' open={openModalValidation} onClose={() => setOpenModalValidation(false)} handleSubmit={() => {sendRequest(); setOpenModalValidation(false)}}>
                <AppAlert message={{color: 'warning', title: '', text: 'Valider la creation de la commande club ?'}}/>
            </AppModal>
        </>
    )
}

export default AddCommandeClub